import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Container, Box, Typography, TextField, Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { newLead } from '../../api';
import { notifyError, notifySuccess } from '../../lib/notification';
import { DashboardLayout } from '../DashboardLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../store';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  createdBy: Yup.string().required('Created By is required'),
  converted: Yup.boolean(),
  phone: Yup.string(),
  email: Yup.string().email('Invalid email address'),
  address: Yup.string().required('Address is required'),
  website: Yup.string().matches(
    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
    'Invalid URL'
  ),
  contactPerson: Yup.string().required('Contact Person is required')
});

const NewLead: React.FC = () => {
  const { pid } = useParams<{ pid: string }>();
  const { user }: any = useStore((state: any) => state);
  const navigate = useNavigate();
  // const [file, setFile] = useState<File | null>(null);
  const formik = useFormik({
    initialValues: {
      name: '',
      createdBy: `${user?.email || ''}`,
      converted: false,
      phone: '',
      email: '',
      address: '',
      website: '',
      contactPerson: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // to-do validate form
      if (pid) {
        addLeadMutation.mutate({ ...values, productId: +pid });
      }
    }
  });
  const addLeadMutation = useMutation(newLead, {
    onSuccess: async () => {
      formik.resetForm();
      notifySuccess('Lead created!');
      navigate('/leads');
    },
    onError: () => {
      notifyError('Lead creation failed');
    }
  });

  // const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const uploadedFile = event.target.files?.[0];
  //   if (uploadedFile) {
  //     setFile(uploadedFile);
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const data = e.target?.result;
  //       if (data) {
  //         if (uploadedFile.type === 'application/json') {
  //           const json = JSON.parse(data as string);
  //           console.log(json);
  //         } else if (
  //           uploadedFile.type ===
  //             'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
  //           uploadedFile.type === 'application/vnd.ms-excel'
  //         ) {
  //           const workbook = XLSX.read(data, { type: 'binary' });
  //           const sheetName = workbook.SheetNames[0];
  //           const sheet = workbook.Sheets[sheetName];
  //           const json = XLSX.utils.sheet_to_json(sheet);
  //           console.log(json);
  //         } else if (uploadedFile.type === 'text/csv') {
  //           const csv = data as string;
  //           const lines = csv.split('\n');
  //           const result = lines.map((line) => line.split(','));
  //           console.log(result);
  //         }
  //       }
  //     };
  //     reader.readAsBinaryString(uploadedFile);
  //   }
  // };

  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4
        }}>
        <Container maxWidth="sm">
          <Box mt={4}>
            <Typography variant="h4" component="h1" gutterBottom>
              Add New Lead
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                label="Business Name/Fullname"
                variant="outlined"
                fullWidth
                margin="normal"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              {/* <TextField
                label="Customer ID"
                variant="outlined"
                fullWidth
                margin="normal"
                name="customerId"
                value={formik.values.customerId}
                onChange={formik.handleChange}
                error={formik.touched.customerId && Boolean(formik.errors.customerId)}
                helperText={formik.touched.customerId && formik.errors.customerId}
              /> */}
              <TextField
                label="Created By"
                variant="outlined"
                fullWidth
                disabled
                margin="normal"
                name="createdBy"
                value={formik.values.createdBy}
                onChange={formik.handleChange}
                error={formik.touched.createdBy && Boolean(formik.errors.createdBy)}
                helperText={formik.touched.createdBy && formik.errors.createdBy}
              />
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                margin="normal"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                margin="normal"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
              <TextField
                label="Website"
                variant="outlined"
                fullWidth
                margin="normal"
                name="website"
                value={formik.values.website}
                onChange={formik.handleChange}
                error={formik.touched.website && Boolean(formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
              />
              <TextField
                label="Contact Person"
                variant="outlined"
                fullWidth
                margin="normal"
                name="contactPerson"
                value={formik.values.contactPerson}
                onChange={formik.handleChange}
                error={formik.touched.contactPerson && Boolean(formik.errors.contactPerson)}
                helperText={formik.touched.contactPerson && formik.errors.contactPerson}
              />
              <Box mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!(formik.isValid && formik.dirty)}>
                  Add Lead
                </Button>
              </Box>
            </form>
            {/* <Box mt={4}>
              <Typography variant="h6" component="h2" gutterBottom>
                Or Upload a File
              </Typography>
              <Input
                type="file"
                inputProps={{ accept: '.json, .xls, .xlsx, .csv' }}
                onChange={handleFileUpload}
              />
            </Box> */}
          </Box>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default NewLead;
