import { useEffect } from 'react';
import { Box, Button, Drawer, useMediaQuery } from '@mui/material';
import logo from '../assets/img/logo.png';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { Users as UsersIcon } from '../icons/users';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { NavItem } from './NavItem';
import { useNavigate } from 'react-router-dom';
import NavItemCollapse from './NavItemCollapse';
import '../assets/styles/dashboard.css';
import { useStore } from '../store';
import { notifySuccess } from '../lib/notification';
import { BusinessCenter, SupportAgent, TrackChanges } from '@mui/icons-material';
import { Link } from 'react-router-dom';
// const customer = ['Dashboard', 'Resources.Products', 'Users'];

const items = [
  {
    to: '/dashboard',
    icon: <ChartBarIcon fontSize="small" />,
    title: 'Dashboard'
  },
  {
    to: '/erp',
    icon: <BusinessCenter fontSize="small" />,
    title: 'ERP',
    subItems: [
      {
        to: '/customers',
        title: 'Customers'
      },
      {
        to: '/invoices',
        title: 'Invoices'
      },
      {
        to: '/receipts',
        title: 'Receipts'
      },
      {
        to: '/products',
        title: 'Products'
      },
      {
        to: '/sales',
        title: 'Sales'
      },
      {
        to: '/expenses',
        title: 'Expenses'
      },
      {
        to: '/apps',
        title: 'App'
      },
      {
        to: '/budget',
        title: 'Budget',
        admin: true
      },
      {
        to: '/timesheet',
        title: 'Timesheet'
      },
      {
        to: '/website-builder',
        title: 'Website Builder'
      }
    ]
  },
  {
    to: '/seo',
    icon: <TrackChanges fontSize="small" />,
    title: 'SEO'
  },
  {
    to: '/ads',
    icon: <AdUnitsIcon fontSize="small" />,
    title: 'Campaign'
  },
  {
    to: '/leads',
    icon: <AdUnitsIcon fontSize="small" />,
    title: 'Leads'
  },

  // {
  //   to: '/customers',
  //   icon: <AdUnitsIcon fontSize="small" />,
  //   title: 'Resources',
  //   subItems: [
  //     {
  //       to: '/seo',
  //       title: 'Search Engine Optimization (SEO)'
  //     }
  //   ]
  // },

  {
    to: '/users',
    icon: <UsersIcon fontSize="small" />,
    title: 'Users'
  },
  // {
  //   to: '/appointment',
  //   icon: <CalendarMonth fontSize="small" />,
  //   title: 'Appointment'
  // },
  {
    to: '/integrations',
    icon: <IntegrationInstructionsIcon fontSize="small" />,
    title: 'Integrations'
  },
  {
    to: '/support',
    icon: <SupportAgent fontSize="small" />,
    title: 'Support'
  },
  // {
  //   to: '/account',
  //   icon: <MailIcon fontSize="small" />,
  //   title: 'Connect',
  //   subItems: [
  //     {
  //       to: '/email',
  //       title: 'Email'
  //     },
  //     {
  //       to: '/sms',
  //       title: 'SMS'
  //     },
  //     {
  //       to: '/whatsapp',
  //       title: 'WhatsApp'
  //     },
  //     {
  //       to: '/facebook',
  //       title: 'Facebook'
  //     },
  //     {
  //       to: '/twitter',
  //       title: 'Twitter'
  //     },
  //     {
  //       to: '/instagram',
  //       title: 'Instagram'
  //     },
  //     {
  //       to: '/linkedin',
  //       title: 'LinkedIn'
  //     },
  //     {
  //       to: '/youtube',
  //       title: 'YouTube'
  //     },
  //     {
  //       to: '/google',
  //       title: 'Google'
  //     },
  //     {
  //       to: '/domain',
  //       title: 'Domain'
  //     }
  //   ]
  // },
  {
    to: '/profile',
    icon: <PersonIcon fontSize="small" />,
    title: 'My Profile'
  }
];
// const filteredItems = items.filter((item) => customer.includes(item.title));

export const DashboardSidebar = (props: any) => {
  const { open, onClose } = props;
  const { user, setUser }: any = useStore((state): any => state);
  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });

  useEffect(() => {
    if (open) {
      onClose?.();
    }
  }, []);

  const logout = () => {
    navigate('/login', { replace: true });
    localStorage.removeItem('user');
    setUser(null);
    notifySuccess('You have been logged out successfully');
  };

  const content = (
    <>
      <Box className="dashboard-sidebar">
        <Box component={Link} to="/" className="dashboard-sidebar-logo">
          <img src={logo} alt="alt logo" />
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          {items.map((item) =>
            item.subItems ? (
              <NavItemCollapse key={item.title} item={item} />
            ) : (
              <NavItem key={item.title} icon={item.icon} to={item.to} title={item.title} />
            )
          )}
        </Box>
        {user && (
          <Button
            color="primary"
            endIcon={<LogoutIcon />}
            fullWidth
            onClick={logout}
            sx={{ my: 2, width: '90%', mx: 'auto' }}
            variant="outlined">
            Logout
          </Button>
        )}
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent">
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary">
      {content}
    </Drawer>
  );
};
