import { Box, Container } from '@mui/material';
import { DashboardLayout } from '../../components/DashboardLayout';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../lib/queryKeys';
import { getStaffGroup } from '../../api';
import { useStore } from '../../store';
import LoadingTable from '../../components/LoadingTable';
import ErrorTable from '../../components/ErrorTable';
import UsersTable from '../../components/users/UsersTable';
import CustomToolbar from '../../components/general/CustomToolbar';

const Users = () => {
  const { user } = useStore((state: any) => state);
  const { data, isLoading, error } = useQuery(
    [queryKeys.users, { adminId: user?.id }],
    getStaffGroup,
    {
      enabled: !!user
    }
  );
  const customer = data?.[0]?.customer;
  const staffData = data?.[0]?.staff;

  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4
        }}>
        <Container maxWidth={false}>
          <CustomToolbar
            title={`Users ${customer?.fullname}`}
            id={customer?.id}
            addPath={'/users/add'}
          />
          <Box sx={{ mt: 3 }}>
            {isLoading ? (
              <LoadingTable />
            ) : error ? (
              <ErrorTable />
            ) : (
              <UsersTable data={staffData} />
            )}
          </Box>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Users;
