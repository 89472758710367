import { Card, CardContent, CardHeader } from '@mui/material';
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { tLead } from '../../types/types';

const LeadChart: React.FC<{ leads: tLead[] }> = ({ leads }) => {
  const conversionRateData = [
    { name: 'Leads', value: leads.length },
    { name: 'Converted', value: leads.filter((lead) => lead.converted).length }
  ];

  return (
    <Card>
      <CardHeader title="Conversion Rate" />
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={conversionRateData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              label
            />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
export default LeadChart;
