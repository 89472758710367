import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { muiTheme } from './assets/styles/themes';
import ProtectedRoute from './components/ProtectedRoute';
import AddApps from './components/admin/AddApps';
import AddBudget from './components/admin/AddBudget';
import AddTimeSheet from './components/admin/AddTimeSheet';
import CustomerForm from './components/customer';
import CustomerDetails from './components/customer/CustomerDetails';
import InvoiceDetails from './components/invoice/InvoiceDetails';
import ViewReceipt from './components/receipt/ViewReceipt';
import { useStore } from './store';
import ErrorPage from './views/404';
import Appointment from './views/Appointment';
import Integrations from './views/Integrations';
import Profile from './views/Profile';
import Test from './views/Test';
import AdPage from './views/ad';
import Budget from './views/admin/Budget';
import Timesheet from './views/admin/Timesheet';
import Forgot from './views/auth/Forgot';
import Login from './views/auth/Login';
import Register from './views/auth/Register';
import ResetPassword from './views/auth/ResetPassword';
import Email from './views/connect/Email';
import SMS from './views/connect/SMS';
import Customers from './views/customers';
import Dashboard from './views/dashboard';
import Home from './views/home';
import ProductExp from './views/product';
import Apps from './views/product/Apps';
import ExpenseAdd from './views/resources/ExpenseAdd';
import Expenses from './views/resources/Expenses';
import InvoiceAdd from './views/resources/InvoiceAdd';
import Invoices from './views/resources/Invoices';
import Products from './views/resources/Products';
import Receipts from './views/resources/Receipts';
import Sales from './views/resources/Sales';
import Seo from './views/resources/Seo';
import SeoResult from './views/resources/SeoResult';
import SeoSearch from './views/resources/SeoSearch';
import SeoSingleResult from './views/resources/SeoSingleResult';
import WebsiteEdit from './views/resources/WebsiteEdit';
import WebsiteGenerator from './views/resources/WebsiteGenerator';
import WebsitePreview from './views/resources/WebsitePreview';
import Addstaff from './views/user/Addstaff';
import Users from './views/user/Users';
import Landing from './views/landing';
import Privacy from './components/privacy/Privacy';
import Contact from './components/contact/Contact';
import SupportPage from './views/support';
import LeadsDashboard from './views/lead';
import AddLead from './views/lead/AddLead';
// import Langc from './views/resources/Langc';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM
};
TagManager.initialize(tagManagerArgs);

// Create a client
const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();
  const { user, setUser }: any = useStore((state): any => state);
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const session = sessionStorage.getItem('token');
    const publicRoutes = [
      '/login',
      '/register',
      '/forgot-password',
      '/contact',
      '/',
      '/privacy',
      '/seo',
      '/seo-result'
    ];

    const isPublicRoute = publicRoutes.includes(location.pathname);

    if (!session && !isPublicRoute) {
      navigate('/login', { replace: true });
      localStorage.removeItem('user');
      setUser(null);
    }

    if (user) {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);

      if (!isPublicRoute) {
        navigate('/', { replace: true });
        localStorage.removeItem('user');
        setUser(null);
      }
    }
  }, [location.pathname, user, navigate]);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={muiTheme}>
        <Routes>
          <Route path="/landing" element={<Home />} />
          <Route path="/" element={<Landing />} />
          <Route
            path="/expiring"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <ProductExp />
              </ProtectedRoute>
            }
          />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route
            path="/users"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/add"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Addstaff />
              </ProtectedRoute>
            }
          />
          <Route
            path="/test"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Test />
              </ProtectedRoute>
            }
          />
          <Route
            path="/support"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <SupportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Customers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers/details/:id"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <CustomerDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers/new"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <CustomerForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers/edit/:id"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <CustomerForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/receipts"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Receipts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/receipts/:id"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <ViewReceipt />
              </ProtectedRoute>
            }
          />
          <Route
            path="/invoices"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Invoices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/integrations"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Integrations />
              </ProtectedRoute>
            }
          />
          <Route
            path="/invoices/:id"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <InvoiceDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/invoice/add/:cid?"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <InvoiceAdd />
              </ProtectedRoute>
            }
          />
          <Route
            path="/invoice/add"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <InvoiceAdd />
              </ProtectedRoute>
            }
          />
          <Route
            path="/products"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Products />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product/website/edit/:pid"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <WebsiteEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/appointment"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Appointment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/expenses"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Expenses />
              </ProtectedRoute>
            }
          />
          <Route
            path="/expenses/add/:cid?"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <ExpenseAdd />
              </ProtectedRoute>
            }
          />
          <Route
            path="/email"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Email />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sms"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <SMS />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sales"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Sales />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ads"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <AdPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/leads"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <LeadsDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/leads/add/:pid"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <AddLead />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/apps"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Apps />
              </ProtectedRoute>
            }
          />
          <Route
            path="/budget"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Budget />
              </ProtectedRoute>
            }
          />
          <Route
            path="/apps/add"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <AddApps />
              </ProtectedRoute>
            }
          />
          <Route
            path="/budget/add"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <AddBudget />
              </ProtectedRoute>
            }
          />
          <Route
            path="/timesheet/add"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <AddTimeSheet />
              </ProtectedRoute>
            }
          />
          <Route
            path="/timesheet"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Timesheet />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sales"
            element={
              <ProtectedRoute isSignedIn={isSignedIn}>
                <Sales />
              </ProtectedRoute>
            }
          />
          <Route path="/website-builder" element={<WebsiteGenerator />} />
          <Route path="/seo" element={<Seo />} />
          <Route path="/seo/:id" element={<SeoSearch />} />
          <Route path="/seo-result" element={<SeoResult />} />
          <Route path="/seo-result/:id" element={<SeoSingleResult />} />
          <Route path="/preview/:id" element={<WebsitePreview />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<Forgot />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="register" element={<Register />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="talk" element={<Langc />} /> */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <ToastContainer theme="colored" autoClose={3000} />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
