import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { tLead } from '../../types/types';
import { useState } from 'react';
import { addRemark } from '../../api';
import dayjs from 'dayjs';

const LeadTable: React.FC<{ leads: tLead[] }> = ({ leads }) => {
  const [open, setOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState<any>(null);
  const [newRemark, setNewRemark] = useState('');
  const handleClickOpen = (lead: any) => {
    setSelectedLead(lead);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedLead(null);
    setNewRemark('');
  };

  const handleAddRemark = async () => {
    if (selectedLead) {
      const today = dayjs().format('YYYY-MM-DD HH:mm:ss');
      selectedLead.remarks = [...(selectedLead.remarks || []), `${today}:${newRemark}`];
      selectedLead.updatedOn = today;
      await addRemark(selectedLead.id, `${today}:${newRemark}`);
      handleClose();
    }
  };
  return (
    <Box>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Remarks</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leads.map((lead, index) => (
              <TableRow key={index}>
                <TableCell>{lead.name}</TableCell>
                <TableCell>{lead?.contactPerson}</TableCell>
                <TableCell>{lead?.email}</TableCell>
                <TableCell>{lead?.address}</TableCell>
                <TableCell>{lead?.phone}</TableCell>
                <TableCell>
                  {lead?.remarks?.map((item: string) => (
                    <p key={item}>{item}</p>
                  ))}
                </TableCell>
                <TableCell>
                  <ButtonGroup orientation="vertical">
                    <Button variant="text" onClick={() => handleClickOpen(lead)}>
                      Add Remark
                    </Button>
                    {/* <Button variant="text">Edit</Button> */}
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Remark</DialogTitle>
        <DialogContent>
          <DialogContentText>Add a new remark for {selectedLead?.name}.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Remark"
            type="text"
            fullWidth
            value={newRemark}
            onChange={(e) => setNewRemark(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddRemark} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default LeadTable;
